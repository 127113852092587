import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./authenticatedTemplateComponent.module.scss";
import { ClientAuthError, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";
import HorizonLogo from "../../assets/horizon-logo-mid-tran.png";
import { SignOutButton } from "../sign-out-button/SignOutButton";
import { useMsal } from "@azure/msal-react";
import { horizonOnlineScopes } from "../../auth/authConfig";
import { PlatformSelectionDropdownMenu } from "../PlatformSelectionDropdownMenu";
import { AccessTokenValidator } from "../AccessTokenValidator";
import { ContactForm } from "../ContactForm";
import { Link } from "@mui/material"

import {
  HZ_AUTH_COOKIE_KEY,
  RETURN_URL_QUERY_KEY,
  LOGIN_PATH,
} from "../constants";

const AuthenticatedTemplateComponent = () => {
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState();
  const [scopes, setScopes] = useState([]);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      if (!isAuthenticated) {
        return;
      }

      const response = await instance
        .acquireTokenSilent({
          ...horizonOnlineScopes,
          account: accounts[0]
        })
        .catch((e) => {
          if (e instanceof ClientAuthError) {
            console.log(e);
            alert("Unable to complete microsoft authentication.");
            window.location.reload(false);
          }
          else if (e instanceof InteractionRequiredAuthError) {
            console.log("Aquire token by redirect");
            instance
              .acquireTokenRedirect({
                ...horizonOnlineScopes,
                account: accounts[0]
              })
              .then((res) => res)
              .catch((e) => {
                console.log(e);
              });
          }
          else {
            console.log(e);
          }
        })
        .then((res) => res);

      if (response?.accessToken === undefined)
      {
        alert("Unable to complete microsoft authentication.");
        window.location.reload(false);
      }

      setAccount(response.account);

      // cookie used for jwt auth on sign-in
      document.cookie = `${HZ_AUTH_COOKIE_KEY}=${response.accessToken}; path=/;`;

      if (!window.location.search.endsWith("contact") && !window.location.search.endsWith("received")) {
        await getScopes(response.accessToken);
      }

      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleRedirect = async (id) => {
    console.log(`Signin ${id}`);
    const urlParams = new URLSearchParams(window.location.search);

    let returnURL =
      urlParams && urlParams?.get(RETURN_URL_QUERY_KEY)
        ? urlParams.get(RETURN_URL_QUERY_KEY).toString()
        : null;

    if (window.location.search.endsWith('signout')) {
      return;
    } else if (!returnURL) {
      window.location.href = `${LOGIN_PATH}/${id}?next=`;
    } else if (!returnURL.includes("oauth-authorized")) {
      window.location.href = `${LOGIN_PATH}/${id}?next=${returnURL}`;
    }
  };

  const getScopes = async (token) => {
    try {
      const result = await axios.get("/scopes", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (result?.data) {

        console.log(`${result.data.length} scopes`)
        setScopes(result.data);

        if (result.data.length === 1) {
          // when only only platform
          if (window.location.href.includes("/logout")) {
            // log out fully
            await handleLogout();
          }
          else {
            // automatically log in
            await handleRedirect(result.data[0].id);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogout = async () => {
    await instance
      .handleRedirectPromise()
      .then(async (authResult) => {
        await instance.logoutRedirect({ postLogoutRedirectUri: "/login" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={styles["splash-screen"]}>
        <div className={styles["auth-container"]}>
          <center>
            <div>
              <img
                className={styles["horizon-logo"]}
                src={HorizonLogo}
                alt="Horizon Customer Data Platform"
              />
            </div>
            <div>
            {isLoading ? (
              <></>
            ) :
            scopes.length > 1 ? ( 
              <PlatformSelectionDropdownMenu platforms={scopes}/>
            ) :
            window.location.search.endsWith("contact") ?
            (
              <ContactForm identity={account}/>
            ) : 
            window.location.search.endsWith("received") ? (
              <span className={styles["received"]}>
                Request recieved.
                We will be in contact.
              </span>
            ) : 
            scopes.length === 0 ? (
              <div className={styles["access-token-container"]}>
                <AccessTokenValidator />
                <Link 
                  href="/login?contact" 
                  color="#616161"
                >How do I get an access key?</Link>
              </div>
            ) : 
            (
              <></>
            )}
            </div>
            <div className={styles["sign-out"]}>
              <SignOutButton />
            </div>
          </center>
        </div>
      </div>
    </>
  );
};

export default AuthenticatedTemplateComponent;


