import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import "./App.css";
import AuthenticatedTemplateComponent from "./components/authenticated-template/AuthenticatedTemplateComponent";
import UnauthenticatedTemplateComponent from "./components/unauthenticated-template/UnauthenticatedTemplateComponent";

export default function App() {
  return (
    <div className="App">
      <UnauthenticatedTemplate>
        <UnauthenticatedTemplateComponent />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AuthenticatedTemplateComponent />
      </AuthenticatedTemplate>
    </div>
  );
}
