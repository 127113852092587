import React from "react";
import styles from "./unauthenticatedTemplateComponent.module.scss";
import { SignInButton } from "../sign-in-button/SignInButton";
import HorizonLogo from "../../assets/horizon-logo-mid-tran.png";

const UnauthenticatedTemplateComponent = () => {
  return (
    <>
      <div className={styles["background"]}>
        <div className={styles["unauth-container"]}>
          <center>
            <img
              className={styles["horizon-logo"]}
              src={HorizonLogo}
              alt="Horizon Customer Data Platform"
            />
            <SignInButton />
          </center>
        </div>
      </div>
    </>
  );
};

export default UnauthenticatedTemplateComponent;
