import * as React from 'react';
import { IconButton, FormGroup, TextField, Grid } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { theme } from './theme'

export function AccessTokenValidator() {
  const [disableSubmit, setDisableSubmit] = React.useState(true);
  const [key, setKey] = React.useState("");

  const handleKeyEntry = (e) => {

    if (e.target?.value === undefined) {
      return;
    }

    if (e.target.value.length>12) {
      e.target.value = e.target.value.substr(0,12);
      return;
    }
    
    e.target.value = e.target.value.toUpperCase();

    setKey(e.target.value);
    setDisableSubmit(e.target.value.length!==12);
  }

  const handleEnterPressed = (e) => {
    if (e.key === 'Enter' && !disableSubmit) {
      handleValidateKey();
    }
  }

  const handleValidateKey = () => {
    window.location.href = `/validate?key=${key}`;
  }

  return (
    <Grid 
      container width={"fit-content"} 
      direction="column"
      alignItems="center"
    >
      <FormGroup row >
        <TextField 
          size="small"
          placeholder="ACCESS KEY"
          InputProps={{
            style: {
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              textTransform: "uppercase",
              border: "1px solid",
              borderColor: "secondary.main",
              borderRightWidth: "0px",
              minWidth: '20ch'
            }
          }}
          onChange={handleKeyEntry}
          onKeyDownCapture={handleEnterPressed}
          theme={theme}
        />
        <IconButton
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            border: "1px solid",
            borderLeftWidth: "0px",
            borderColor: "secondary.main",
            background: "rgba(61,61,61,0.2)"
          }}
          type="submit"
          variant="contained"
          theme={theme}
          disabled={disableSubmit}
          onClick={()=>handleValidateKey()}
        >
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
      </FormGroup>
    </Grid>
  );
}