import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
      primary: {
        main: '#616161',
      },
      secondary: {
        main: '#313131',
      }
    }
  })