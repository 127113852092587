import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { theme } from './theme'

import {
    RETURN_URL_QUERY_KEY,
    LOGIN_PATH
  } from "./constants";
import { Typography } from '@mui/material';

const ITEM_HEIGHT = 48;

export function PlatformSelectionDropdownMenu({platforms}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const scopeClick = (id) => {
    const urlParams = new URLSearchParams(window.location.search);

    let returnURL =
      urlParams && urlParams?.get(RETURN_URL_QUERY_KEY)
        ? urlParams.get(RETURN_URL_QUERY_KEY).toString()
        : null;

    if (!returnURL) {
      window.location.href = `${LOGIN_PATH}/${id}?next=`;
    } else if (!returnURL.includes("oauth-authorized")) {
      window.location.href = `${LOGIN_PATH}/${id}?next=${returnURL}`;
    }
  };

  const clientNameWidth = `${Math.max(...(platforms.map(p => p.clientFullName.length)))+3}ch`

  return (
    <div>
      <Button
        id="menu-button"
        aria-controls={open ? 'menu-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        theme={theme}
      >
        Select Platform
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              minWidth: '20ch'
            }
          }
        }}
      >
      {platforms &&
        platforms?.length > 1 &&
        platforms.sort((a,b)=>a.clientFullName.localeCompare(b.clientFullName)).map((scope) => {
          return (
            <MenuItem key={scope.id} onClick={() => scopeClick(scope.id)} disableRipple>
              <Typography minWidth={clientNameWidth}>{scope.clientFullName}</Typography>
              <Typography>{scope.name}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}