import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import image from "../../assets/ms-icon.png";
import styles from "./signInButton.module.scss";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div
      role="button"
      className={styles["login-btn"]}
      onClick={() => handleLogin()}
    >
      <img className={styles["ms-icon"]} src={image} alt="Microsoft icon"></img>
      <span className={styles["login-text"]}>Sign in with Microsoft</span>
    </div>
  );
};
