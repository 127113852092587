import * as React from 'react';
import { 
  Button, 
  FormGroup, 
  TextField, 
  Box, 
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material";
import { theme } from './theme'

export function ContactForm({identity}) {
  console.log(identity);

  return (
<Box
  display="flex"
  justifyContent="center"
  sx={{'& .MuiTextField-root': {
     m: 1, 
     width: '50ch', 
     backgroundColor: 'rgba(255,255,255,0.6)' 
  }}}
>
  <form action="/login/contactSubmit">
    <FormGroup column>
      <TextField 
        required
        name="name"
        label="Name"
        size="small"
        defaultValue={identity.name}
      />
      <TextField
        required
        name="email"
        label="Email"
        size="small"
        defaultValue={identity.username.includes('@') ? identity.username : '' }
      />
      <TextField
        required
        name="organization"
        label="Organization"
        size="small"
      />
        <TextField
        name="telephone"
        label="Telephone"
        size="small"
      />
      <RadioGroup
        defaultValue="access-existing"
        name="requestType"
        style={{marginLeft: "10px"}}
      >
        <FormControlLabel value="access-existing" control={<Radio />} label="I need to access an existing Horizon instance" />
        <FormControlLabel value="create-new" control={<Radio />} label="I'd like to create a new Horizon instance" />
      </RadioGroup>
      <TextField
        multiline
        name="details"
        label="Details"
        size="small"
        minRows={4}
      />
      <input type="hidden" name="tenantId" value={identity.tenantId} />
      <div>
      <Button 
        type="submit"
        variant="outlined" 
        color="primary" 
        style={{
          border: "2px solid", 
          marginLeft: "8px", 
          width: "7rem", 
          fontWeight: 600,
        }}
        theme={theme}
      >OK</Button>
      </div>
    </FormGroup>
  </form>
</Box>
);
}