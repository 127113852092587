import React from "react";
import { useMsal } from "@azure/msal-react";
import styles from "./signOutButton.module.scss";

/**
 * Renders a sign out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {

    instance.logoutRedirect({
      postLogoutRedirectUri: "/login"
    });
  };

  return (
    <div
      role="button"
      className={styles["sign-out-btn"]}
      onClick={() => handleLogout()}
    >
      <span className={`material-icons-outlined ${styles["sign-out-img"]}`}>
        logout
      </span>
      <span className={styles["sign-out-text"]}>Sign out</span>
    </div>
  );
};
